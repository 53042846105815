import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="container">
      <h1 className="text-center">Fantacy Game Privacy Policy</h1>
      <p>
        Privacy Policy Welcome to Sitaram Exchange, the ultimate fantasy sports
        platform where you can engage in thrilling competitions and showcase
        your skills. Your privacy is of utmost importance to us, and we are
        committed to protecting the personal information you provide while using
        our services. This Privacy Policy outlines how Sitaram Exchange
        collects, uses, and safeguards your data. By accessing or using our
        platform, you agree to the terms described herein. <br />
        1. Information We Collect We collect various types of information to
        enhance your experience and improve our services. The information we
        gather may include: <br />
        1.1. Personal Information: When you register an account with Sitaram
        Exchange, we may collect personal details such as your name, email
        address, date of birth, and location. <br />
        1.2. Account Information: We may collect information related to your
        account activity, including transactions, winnings, and participation in
        contests. <br />
        1.3. Device Information: We automatically collect certain information
        about your device, such as IP address, browser type, operating system,
        and device identifiers. <br />
        1.4. Usage Data: We collect data about your interactions with our
        platform, such as the pages you visit, the features you use, and your
        preferences. <br />
        1.5. Cookies and Similar Technologies: We use cookies and similar
        technologies to personalize your experience, analyze trends, and track
        your activity on our platform. <br />
        2. How We Use Your Information We use the information we collect for
        various purposes, including: <br />
        2.1. Providing Services: We use your information to provide and improve
        our services, including facilitating fantasy sports contests, processing
        transactions, and delivering personalized content. <br />
        2.2. Communications: We may use your contact information to send you
        updates, notifications, and promotional messages about Sitaram Exchange
        and relevant third-party offers. You can opt out of receiving marketing
        communications at any time. <br />
        2.3. Analytics: We analyze user data to understand usage patterns,
        trends, and preferences, which helps us optimize our platform and
        enhance user experience. <br />
        2.4. Security: We use your information to detect and prevent fraudulent
        activity, unauthorized access, and other security threats. <br />
        2.5. Legal Compliance: We may use your information to comply with legal
        obligations, enforce our terms of service, and protect the rights,
        property, and safety of Sitaram Exchange, our users, and the public.{" "}
        <br />
        3. Data Sharing and Disclosure We may share your information with third
        parties under the following circumstances: <br />
        3.1. Service Providers: We may share your information with third-party
        service providers who assist us in operating our platform, processing
        payments, conducting analytics, and delivering marketing communications.
        <br />
        3.2. Business Partners: We may share your information with business
        partners who offer products or services that may be of interest to you.
        <br />
        3.3. Legal Requirements: We may disclose your information in response to
        legal requests, court orders, or government regulations, or to protect
        against legal liability. <br />
        <br />
        3.4. Corporate Transactions: In the event of a merger, acquisition, or
        sale of assets, we may transfer your information to the relevant parties
        involved in the transaction. <br />
        4. Data Retention We retain your information for as long as necessary to
        fulfill the purposes outlined in this Privacy Policy, unless a longer
        retention period is required or permitted by law. <br />
        5. Your Rights and Choices 5.1. Access and Correction: You have the
        right to access and update your personal information stored in your
        Sitaram Exchange account. You can review and edit your account details
        at any time. <br />
        5.2. Data Deletion: You can request the deletion of your account and
        associated data by contacting us. However, please note that certain
        information may be retained for legal or legitimate business purposes.
        <br />
        5.3. Marketing Preferences: You can opt out of receiving marketing
        communications from us by following the unsubscribe instructions
        provided in the messages or by contacting us directly. <br />
        6. Data Security We employ industry-standard security measures to
        protect your information from unauthorized access, alteration,
        disclosure, or destruction. However, please be aware that no method of
        transmission over the internet or electronic storage is 100% secure, and
        we cannot guarantee absolute security. <br />
        7. International Data Transfers Your information may be transferred to
        and processed in countries other than your own, where data protection
        laws may differ. By using Sitaram Exchange, you consent to the transfer
        of your information to these countries. <br />
        8. Children's Privacy Sitaram Exchange is not intended for use by
        individuals under the age of 18. We do not knowingly collect personal
        information from children, and if we become aware that we have
        inadvertently collected such information, we will take steps to delete
        it.
        <br />
        9. Changes to this Privacy Policy We may update this Privacy Policy from
        time to time to reflect changes in our practices or legal requirements.
        We will notify you of any material changes by posting the updated policy
        on our platform or by other means of communication. <br />
        10. Contact Us If you have any questions, concerns, or requests
        regarding this Privacy Policy or our data practices, please contact us
        at{" "}
        <span>
          <b>sitaramexchange321@gmail.com</b>
        </span>
        .
      </p>
    </div>
  );
}
