import React from "react";

export default function Home() {
  return (
    <div className="container">
      <h1 className="text-center">Home</h1>
      <div className="main_img">
        <img
          className="img-fluid w-100"
          src="https://www.sportphotogallery.com/content/images/cmsfiles/product/41342/42260-list.jpg"
        />
        <button className="btn_join px-5 p-2">Join Now</button>
      </div>
      <img
        className="img-fluid w-100"
        src="https://c.ndtvimg.com/2023-11/kd4gm0r_virat-kohli-afp_625x300_17_November_23.jpg?im=FeatureCrop,algorithm=dnn,width=806,height=605"
      />
    </div>
  );
}
