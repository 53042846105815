import React from "react";

export default function Aboutus() {
  return (
    <div className="container">
      <h1 className="text-center">About Us</h1>
      <p>
        Welcome to Sitaram Exchange, Your Gateway to Fantasy Sports!
        <br /> Sitaram Exchange is a one-stop platform for all your fantasy
        sports needs, designed to bring the thrill and excitement of the
        sporting world right to your fingertips. We're a passionate team of
        sports enthusiasts dedicated to creating an immersive and engaging
        fantasy sports experience for players of all levels. Our Story <br />{" "}
        Sitaram Exchange was born from a shared love of sports and a desire to
        create a platform that would elevate the fantasy sports experience. We
        saw a gap in the market for a platform that offered a user-friendly
        interface, diverse sports options, and a strong focus on player
        engagement.
        <br /> Fueled by this vision, we embarked on a journey to develop a
        platform that would not only cater to seasoned fantasy sports veterans
        but also welcome newcomers with open arms. We believe that fantasy
        sports should be accessible and enjoyable for everyone, regardless of
        their experience level.
        <br /> Why Choose Sitaram Exchange?
        <br /> Here are just a few reasons why Sitaram Exchange is the perfect
        platform for your next fantasy sports adventure
        <br /> <b>Unmatched Variety:</b> We offer a comprehensive selection of
        sports to choose from, including cricket, football, basketball, kabaddi,
        and many more. Whether you're a die-hard cricket fan or an avid follower
        of international football, we have something for everyone.
        <br /> <b>Seamless User Experience:</b> Our user-friendly platform is
        designed with both novice and experienced players in mind. With
        intuitive navigation, clear instructions, and a wealth of resources,
        you'll be drafting your dream team and competing in leagues in no time.
        <br /> <b>Fair Play & Transparency:</b> We are committed to providing a
        fair and transparent playing environment for all our users. Our robust
        anti-cheating measures ensure that everyone competes on a level playing
        field.
        <br /> <b>Thrilling Competitions:</b> Join a variety of leagues, from
        free-to-play contests to high-stakes competitions. Test your skills
        against other fantasy sports enthusiasts and climb the leaderboard to
        win exciting prizes and bragging rights.
        <br /> <b>Engaging Community:</b> Become part of a vibrant and
        passionate community of sports fans. Share your strategies, discuss the
        latest games, and engage in friendly banter with fellow players on our
        interactive forum.
        <br /> <b>Regular Updates & Enhancements:</b> We are constantly working
        to improve your Sitaram Exchange experience. We listen to your feedback
        and suggestions, and we strive to provide you with the best possible
        platform for your fantasy sports enjoyment.
        <br /> More Than Just a Game
        <br /> At Sitaram Exchange, we believe that fantasy sports are more than
        just a game. It's a way to connect with friends and family who share
        your passion for sports. It's a platform to test your knowledge,
        strategic thinking, and decision-making skills. It's a chance to
        experience the thrill of victory and the agony of defeat, all from the
        comfort of your own home.
        <br /> We are committed to fostering a positive and inclusive
        environment where everyone feels welcome to participate. We believe that
        sports have the power to bring people together, and we hope that Sitaram
        Exchange can play a role in creating a global community of sports fans.
        Join the Sitaram Exchange Family Today!
        <br /> Whether you're a seasoned fantasy sports pro or a curious
        newcomer, we invite you to join the Sitaram Exchange family. Sign up for
        a free account today, explore our diverse sports offerings, and create
        your dream team. Get ready to experience the excitement of fantasy
        sports like never before!
        <br /> Here are some additional resources you might find helpful
        <br /> <b>FAQs:</b> Our comprehensive FAQ section provides answers to
        frequently asked questions about Sitaram Exchange, our games, and our
        rules.
        <br />
        <br /> <b>Help Center:</b> If you can't find the answer to your question
        in our FAQs, our Help Center offers additional resources and support
        options.
        <br />
        <b>Blog:</b> Stay up-to-date on the latest sports news, fantasy sports
        tips, and exclusive content from our team of experts on our blog.
        <br /> We are confident that you'll find Sitaram Exchange to be the
        ultimate destination for all your fantasy sports needs. Let the games
        begin!
      </p>
    </div>
  );
}
