import React from "react";
import { useNavigate, useRoutes } from "react-router-dom";

export default function Navbar() {
  const navigate = useNavigate();
  return (
    <nav class="navbar navbar-expand-sm">
      <div class="container-fluid">
        <h4
          className="nav"
          onClick={() => {
            navigate("/");
          }}
        >
          Fantacy Game
        </h4>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li
              onClick={() => {
                navigate("/");
              }}
              class="nav-item mx-3 nav"
            >
              Home
            </li>
            <li
              onClick={() => {
                navigate("/privacyPolicy");
              }}
              class="nav-item mx-3 nav"
            >
              Privacy Policy
            </li>
            <li
              onClick={() => {
                navigate("/about");
              }}
              class="nav-item mx-3 nav"
            >
              About us
            </li>
            <li
              onClick={() => {
                navigate("/contact");
              }}
              class="nav-item mx-3 nav"
            >
              Contact us
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
