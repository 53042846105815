import React from "react";

export default function Contactus() {
  return (
    <>
      <h1 className="text-center">Contact Us</h1>
      <form className="container w-50 mt-5">
        <label>First Name:</label>
        <input className="form-control" type="text" placeholder="First Name" />
        <br />
        <label>Last Name:</label>
        <input className="form-control" type="text" placeholder="Last Name" />
        <br />
        <label> Email:</label>
        <input className="form-control" type="text" placeholder="Email" />
        <button className="btn btn-primary m-auto d-block mt-5">Submit</button>
      </form>
    </>
  );
}
