import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Component/navbar";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Home from "./Component/Home";
import Aboutus from "./Component/Aboutus";
import Contactus from "./Component/Contactus";
import PrivacyPolicy from "./Component/PrivacyPolicy";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
